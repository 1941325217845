import React from 'react'
import classnames from 'classnames'
import get from 'lodash/get'

import Markdown from '@components/markdown'
import { withLazyRef } from '@util/lazy_ref'

class HeroBlock extends React.Component {
  getPageBackgroundColor() {
    return this.props.fields.pageBackgroundColor
  }

  render() {
    const fields = this.props.fields || {}
    const blockLayout = fields.layout || ''
    let blockClasses = classnames('heroBlock', this.props.classname, {
      [`heroBlock--${blockLayout.replace(/\s/g, '')}`]: blockLayout,
    })

    const body = get(fields, 'body')
    const id = get(fields, 'anchorId')

    return (
      <div className={blockClasses} id={id}>
        <h1 className="heroBlock__header">{fields.header}</h1>

        {body && <Markdown className="heroBlock__body">{body}</Markdown>}
      </div>
    )
  }
}

export default withLazyRef(HeroBlock)
