import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeExternalLinks from 'rehype-external-links'

const Markdown = ({ children, ...props }) => {
  return (
    <ReactMarkdown
      rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }], rehypeRaw]}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
